import React, { Component } from 'react'
import Map from '../components/map';

class MapPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
     


    }


    render() {
       


        return (
            <div className="iframe-map">
             <Map {...this.props} />
            </div>
        )
    }
}

export default MapPage;
