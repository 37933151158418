import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import Picture1 from '../../images/picture1.png';
import AnimtedButton from '../../components/animatedButton';
import PageWithLayout from '../../containers/page';
import MenuComponent from '../../components/menuComponent';
import KontaktForma from '../../containers/contactForm';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink, Input
} from 'reactstrap'
import { API_ENDPOINT } from '../../constants';


const links = [
    {
        _id: 1,
        link: '/kontakt/kontakt-formular',
        name: 'Kontak formular'
    },
    {
        _id: 2,
        link: '/kontakt/informacije-za-inostrane-pošiljke',
        name: 'Informacije za inostrane pošiljke'
    },
    {
        _id: 3,
        link: '/kontakt/mapa-distributivnih-centara',
        name: 'Mapa distributivnih centara'
    }
];

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.contact = this.contact.bind(this);

        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/aboutus', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                pages: result
            })

        })


    }

    contact(data) {
        fetch(API_ENDPOINT + '/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.setState({
                _done: true
            })

        })

    }

    render() {
        const buttonText = "POŠALJI";

        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Kontakt</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Kontakt</Link>
                                </NavItem>

                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                {
                                    this.state.pages.map((item, idx) => {
                                        return (
                                            <NavItem>
                                                <NavLink to={`/o-nama/${item.alias}`} exact tag={RRNavLink} activeClassName="active">
                                                    {item.name}
                                                </NavLink>
                                            </NavItem>

                                        )
                                    })
                                }
                                <NavItem>
                                    <NavLink to={`/kontakt`} exact tag={RRNavLink} activeClassName="active">
                                        Kontakt forumular
                                                </NavLink>
                                </NavItem>

                            </div>
                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12" className="contactForm">
                            <h6>Kontaktirajte nas</h6>
                            <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>
                            <KontaktForma onSubmit={this.contact} />
                            {this.state._done ?
                                <div class="alert alert-info mt-4" role="alert">Vaša poruka je uspješno poslata!</div>
                                :
                                null
                            }
                        </Col>


                    </Row>
                </Container>

            </div>
        )
    }
}

export default PageWithLayout(ContactForm)
