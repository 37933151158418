import React from 'react';
import mapIcon from '../images/pin.png';
import slika from '../images/news4.png';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const locations = [
  {
    name: 'Tuzla',
    name2: 'BS SIMIN HAN',
    address: '3. Tuzlanske brigade 172',
    location: [44.526882, 18.740506],
    wokrTime: 'od 9.00 h do 15.00 h'
  },
  {
    name: 'Tuzla',
    name2: 'BS HUSINO',
    address: 'Put strane bb',
    location: [44.506351, 18.615221],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Tuzla',
    name2: 'BS TUZLA 1',
    address: 'Husinskih rudara do 168',
    location: [44.525696, 18.631138],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Tuzla',
    name2: 'BS TUZLA 2',
    address: 'Husinskih rudara bb',
    location: [44.524840, 18.629809],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Tuzla',
    name2: 'BS TUZLA 3',
    address: 'Rudarska bb',
    location: [44.536968, 18.653426],
    wokrTime: 'od 9.00 h do 17.45 h'
  },

  {
    name: 'Sarajevo',
    name2: 'BS BRIJEŠĆE',
    address: 'Safeta Zajke bb',
    location: [43.854636, 18.328266],
    wokrTime: 'od 9.00 h do 18.00 h'
  }, 
  {
    name: 'Sarajevo',
    name2: 'BS OTES',
    address: 'Barska bb',
    location: [43.836446, 18.299218],
    wokrTime: 'od 9.00 h do 18.00 h'
  }, 
  {
    name: 'Sarajevo',
    name2: 'BS VOGOŠĆA',
    address: 'Igmanska bb',
    location: [43.902407, 18.335380],
    wokrTime: 'od 9.00 h do 16.00 h'
  }, 
  {
    name: 'Sarajevo',
    name2: 'BS BLAŽUJ',
    address: 'Blažuj 73',
    location: [43.844450, 18.249701],
    wokrTime: 'od 9.00 h do 16.30 h'
  }, 
  {
    name: 'Sarajevo',
    name2: 'BS STUP',
    address: 'Dobrinjska 1',
    location: [43.838261, 18.319032],
    wokrTime: 'od 9.00 h do 17.00 h'
  }, 
  {
    name: 'Sarajevo',
    name2: 'BS DOBRINJA',
    address: 'Branislava Nušića bb',
    location: [43.835023, 18.339015],
    wokrTime: 'od 9.00 h do 18.30 h'
  },
  {
    name: 'Sarajevo',
    name2: 'BS TRANZIT',
    address: 'Put mladih muslimana',
    location: [43.851799, 18.409111],
    wokrTime: 'od 9.00 h do 18.00 h'
  },

  {
    name: 'Banja Luka',
    name2: 'BS BUDŽAK',
    address: 'Ivana Gorana Kovačića 203A',
    location: [44.803261, 17.198647],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Banja Luka',
    name2: 'BS ŠARGOVAC',
    address: 'Bilećka bb',
    location: [44.815153, 17.200291],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Banja Luka',
    name2: 'BS MALTA',
    address: 'Bulevar Srpske Vojske 14a',
    location: [44.781211, 17.205857],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
  {
    name: 'Banja Luka',
    name2: 'BS VIJENAC',
    address: 'Omladinska Ulica bb',
    location: [44.785951, 17.205978],
    wokrTime: 'od 9.00 h do 18.00 h'
  },
];

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      show: false,
    };
  }

  initMap() {
    this.setState({
      _mapInit: true
    });
    var latLng = new window.google.maps.LatLng(43.9, 17.7721895);

    var map = new window.google.maps.Map(this.GoogleMap, {
      zoom: 7.9,
      center: latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      gestureHandling: "gestureHandling",
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#c1cddf"
            },
            {
              "weight": 4
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    });

    for (let i = 0; i < locations.length; i++) {
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(locations[i].location[0], locations[i].location[1]),
        center: locations[i],
        map: map,
        icon: mapIcon
      });
      marker.addListener('click', () => {
        this.setState({ show:locations[i] });
        map.setCenter(new window.google.maps.LatLng(locations[i].location[0], locations[i].location[1]))
      })
    }
  }




  componentDidMount() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }

  }

  componentDidUpdate() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }
  }

  render() {
    return (
      <>
        {
          this.props.mapinit ?
            <div className="googleMap" ref={(input) => { this.GoogleMap = input; }}>
              {this.state.show ?
                <div className="googleModal googleModal2">
                  <div className="googleBox">
                    <button onClick={() => this.setState({ show: null })} className="fa fa-close" />
                    
                    <div className="textBox">
                      <h6>{this.state.show.name}</h6>
                      <h1>{this.state.show.name2}</h1>
                      <h4>{this.state.show.address}</h4>
                      <span>Radno vrijeme:</span>
                     <h3>{this.state.show.wokrTime}</h3>
                    </div>

                        {/*
                          this.state.show ?
                          <div className="pin-modal">
                              <h6>Sarajevo</h6>
                              <h1>BS BRIJEŠĆE SARAJEVO</h1>
                              <h4>Safeta Zajke bb</h4>
                              <span>Radno vrijeme:</span>
                              <h3>od 9.00h do 18.00h</h3>
                          </div>
                          :
                          null
                        */}
                  </div>
                </div> : null}
            </div>

            : null

        }

      </>
    );
  }
}
