import React, { Component } from 'react'
import newsPicture1 from '../images/news1.png';
import newsPicture2 from '../images/news2.png';
import newsPicture3 from '../images/news3.png';
import newsPicture4 from '../images/news4.png';
import AnimtedButton from '../components/animatedButton';
import { Link } from 'react-router-dom';
import moment from 'moment'

import {
    Container,
    Row,
    Col
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';

const articles = [
    {
        _id: 1,
        image: newsPicture1,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Donacija EuroExpress brze pošte za djecu u Dnevnim centrima “Gnijezdo”'
    },
    {
        _id: 2,
        image: newsPicture2,
        allias: '/novosti/detalji',
        date: '15. Oktobar, 2018.',
        title: 'OBAVJEŠTENJE: Nova lokacija distributivnog centra Sarajevo'
    },
    {
        _id: 3,
        image: newsPicture3,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Obavještenje: Neradni dan u Mostaru i Vitezu'
    },
    {
        _id: 4,
        image: newsPicture4,
        allias: '/novosti/detalji',
        date: '08. Novembar, 2018.',
        title: 'Novo: Slovenija – drumski transport'
    }

];



class news extends Component {
    constructor(props) {
        super(props);
        this.state = {
             items: []
        }
    }

    componentDidMount(){
        fetch(API_ENDPOINT + '/lastNews', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                items: result
            })
            console.log(result);
        })


    }
 
    render() {

     
        const buttonText = "ARHIVA NOVOSTI";
        const newButtonDesign = true;
        return (
            
            <Container className= "newsSection">
                <Row>
                    <div className= "multipleTitle">
                        <h1>NOVOSTI</h1>
                        <div className="box">
                            <h3 className="universalh3"><span>Novosti</span> iz kompanije</h3>
                        </div>
                    </div>
                    {this.state.items.map((item, idx) => {                        
                        return (
                            <Col xl="3"  lg= "3" md="6" xs="6" key={item._id} className="newsBoxWrapper2">
                                <Link to={`/novosti/${item.name && item.name.generateAlias()}/${item._id}`}>
                                    <div className="newsBox">
                                        <img src={item.image} className="img-fluid" />
                                        <div className="textBox">
                                            <h6>{item.name}</h6>
                                            <p>{moment.unix(item.timestamp).format('DD. MMMM, YYYY')}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                    <div className = "buttonWrapper">
                        <Link to="/novosti/aktuelna-dešavanja"><AnimtedButton buttonText={buttonText} newButtonDesign={newButtonDesign} /></Link>
                    </div>
                </Row>
            </Container>
        )
    }
}

export default news
