import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import News from '../../components/news';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import newsDetailPicture from '../../images/newsDetail.png';
import PageWithLayout from '../../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'
import { API_ENDPOINT } from '../../constants';



class NewsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }

    }

    componentDidMount(){
        fetch(API_ENDPOINT + '/news/'+this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                ...result
            })
            console.log(result);
        })
        fetch(API_ENDPOINT + '/lastNews/'+this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                items: result
            })
            console.log(result);
        })

    }

    componentDidUpdate(prevProps){
        if (prevProps[0].location.pathname != this.props[0].location.pathname){
            fetch(API_ENDPOINT + '/news/'+this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    ...result
                })
                console.log(result);
            })
            fetch(API_ENDPOINT + '/lastNews/'+this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    items: result
                })
                console.log(result);
            })
    
    
        }
    }

    render() {
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Novosti</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Novosti</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Detalji</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <img src={this.state.image} className="img-fluid" />
                            <h4>{this.state.name}</h4>
                            <p className = "redParagraph">{moment.unix(this.state.timestamp).format('DD. MMMM, YYYY')}</p>
                            <p dangerouslySetInnerHTML={{__html: this.state.content}}></p>
                        </Col>
                        <Col xl="3" lg="3">
                            <h3>Najnovije</h3>
                            
                            {
                                this.state.items.map((item, idx) => {
                                    return (
                                        <div className="newsBoxWrapper2">
                                            <Link to={`/novosti/${item.name && item.name.generateAlias()}/${item._id}`}>
                                            <div className="newsBox">
                                                <img src={item.image} className="img-fluid" />
                                                <div className="textBox">
                                                    <h6>{item.name}</h6>
                                                    <p>{moment.unix(item.timestamp).format('DD. MMMM, YYYY')}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
        
                                    )
                                })
                            }
                        </Col>


                    </Row>

                </Container>
            </div>
        )
    }
}

export default PageWithLayout(NewsDetail)
