
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

class DateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (!this.props.value) {
            this.props.onChange(new Date());
        }
    }

    

    render() {

        return (
            <div className="input-wrap">
                <DatePicker
                    selected={this.props.value}
                    onChange={this.props.onChange}
                    dateFormat="P"
                    dayClassName={(date) =>
                        date.getDay() == 0 || date.getDay() == 6 ? "disabled-date" : undefined
                      }
                    placeholderText="Odaberite datum"
                    className={this.props.error ? 'required' : null}

                />
            </div>
        )
    }
}


export default DateComponent;