
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import TimePicker from 'react-time-picker';

class Time extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="time-wrap">
                <TimePicker
                    onChange={this.props.onChange}
                    value={this.props.value}
                    disableClock
                    clearIcon={null}
                    format="HH:mm"
                />
            </div>
        );
    }
}

export default Time;