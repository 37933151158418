import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import Navigacija from '../containers/navbar';
import News from '../components/news';
import Footer from '../containers/footer';
import { Link } from 'react-router-dom';
import newsDetailPicture from '../images/newsDetail.png';
import AnimtedButton from '../components/animatedButton';
import PageWithLayout from '../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink,
    Input, FormGroup, Table, Form, Label, Button
} from 'reactstrap'

import option1Icon from '../images/svg/o1.svg';
import option2Icon from '../images/svg/o2.svg';
import option3Icon from '../images/svg/o3.svg';
import option4Icon from '../images/svg/o4.svg';
import option5Icon from '../images/svg/o5.svg';
import option6Icon from '../images/svg/o6.svg';
import minusIcon from '../images/svg/minus.svg';
import plusIcon from '../images/svg/plus.svg';

import Date from '../components/forms/fields/date';

class ShipmentDirection extends Component {
    constructor(props) {
        super(props);
        this.track = this.track.bind(this);

        this.state = {
            deliveryNewDay: false,
            deliveryOffice: false,
            deliveryNewAddress: false,
            deliveryWrongPhone: false,
            deliveryUnwanted: false,
            zones: []
        }
    }

    componentDidMount() {
        if (this.props[0].match.params.code) {
            this.setState({
                code: this.props[0].match.params.code,
                phone: this.props[0].match.params.phone,
            }, () => this.track())
        }

        fetch('https://gateway.euroexpress.ba:8083/api/v1/pub/zones').then((res) => res.json()).then((result) => {
            console.log(result)
            this.setState({
                zones: result
            })
        })

    }

    track() {
        fetch('https://gateway.euroexpress.ba:8083/api/v1/pub/track?trackingNumber=' + this.state.code).then((res) => res.json()).then((result) => {
            console.log(result)
            if (result.error) {
                this.setState({
                    _error: true,
                    data: null
                })
            } else {

                fetch('https://gateway.euroexpress.ba/sgs-api/posiljkaPrim', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'API-KEY': 'QxwNKo3eAMbmqdavYnlqGp1d'
                    },
                    body: JSON.stringify({
                        sifraPosiljke: result.sifra,
                        primaocTelefon: this.state.phone
                    })
                }).then((res) => res.json()).then((result1) => {
                    if (result1.ErrorMsg) {
                        this.setState({
                            _error2: result1.ErrorMsg,
                            data1: null
                        })
                    } else {
                        this.setState({
                            data1: result1,
                            _error: null
                        })
                    }
                })


                this.setState({
                    data: result,
                    _error: null
                })
            }
        })


    }


    submitRequest = () => {
        let obj = { ...this.state.data1 };

        if (this.state.deliveryNewDay) {
            obj.vrstaIntervencijeSifra = 71;
            obj.napomena = this.state.napomena;
            obj.datumDostave = moment.unix(Math.floor(this.state.date.getTime() / 1000)).format('YYYY-MM-DD')
        }
        if (this.state.deliveryOffice) {
            obj.vrstaIntervencijeSifra = 70;
            obj.adresaPrimId = this.state.addressId;
            obj.adrese = [];
            for (let i = 0; i < this.state.data1.adrese.length; i++) {
                if (this.state.data1.adrese[i].id == obj.adresaPrimId) {
                    obj.adrese.push(this.state.data1.adrese[i]);
                }
            }
            // obj.datumDostave = moment.unix(Math.floor(this.state.date.getTime() / 1000)).format('YYYY-MM-DD')
            obj.napomena = this.state.napomena;
        }
        if (this.state.deliveryNewAddress) {
            obj.vrstaIntervencijeSifra = 72;
            //obj.datumDostave = moment.unix(Math.floor(this.state.date.getTime() / 1000)).format('YYYY-MM-DD')
            obj.napomena = this.state.napomena;
        }
        if (this.state.deliveryWrongPhone) {
            obj.vrstaIntervencijeSifra = 73;
            obj.napomena = this.state.napomena;
        }
        if (this.state.deliveryUnwanted) {
            obj.vrstaIntervencijeSifra = 74;
            obj.napomena = this.state.napomena;
        }

        fetch('https://gateway.euroexpress.ba/sgs-api/intervencija', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'API-KEY': 'QxwNKo3eAMbmqdavYnlqGp1d'
            },
            body: JSON.stringify(obj)
        }).then((res) => res.json()).then((result) => {
            this.setState({ done: true })
        })


    }

    render() {
        const buttonText = "PRONAĐITE POŠILJKU";
        console.log(this.props)
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Upravljanje dostavom</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Upravljanje dostavom</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            {/*<div className="flex">
                                <p>Unesite sigurnosni kod koji ste dobili u SMS poruci da bi mogli pristupiti opcijama:</p>
                                <Input type="search" placeholder="Unesite kod vaše pošiljke" value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
        </div>*/}
                            {
                                this.state._error ?
                                    <p style={{ marginTop: '10px', color: '#DA212E' }}>Greška, pošiljka nije pronađena!</p>
                                    :

                                    null
                            }
                        </Col>
                        <Col lg="4">
                            {this.state.data ?

                                <button className="shipment-number">Pošiljka broj: <span>{this.state.data.sifra}</span></button>
                                :
                                null
                            }
                        </Col>
                        <Col lg="12">
                            <div className="h-scroll">
                                <table className="shipment-details">
                                    <tr>
                                        <th>Datum i vrijeme promjene</th>
                                        <th>Mjesto</th>
                                        <th>Opis događaja</th>
                                    </tr>
                                    {
                                        this.state.data && this.state.data.events && this.state.data.events.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <td>{moment(item.datum).format('DD MMM YYYY, HH:mm')}</td>
                                                    <td>{item.centar}</td>
                                                    <td>{item.opis}</td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </Col>
                        {this.state.data ?
                            <Col xl="6" lg="6" md="6" xs="12" className="table2">
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Podaci o pošiljci</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data1 ?
                                            <tr>
                                                <td>Naziv primaoca:</td>
                                                <td>{this.state.data1.primaocNaziv}</td>
                                            </tr>
                                            :
                                            null
                                        }
                                        {false && this.state.data1 ?

                                            <tr>
                                                <td>Mjesto primaoca:</td>
                                                <td>{this.state.data1.primaocMestoOpis}</td>
                                            </tr>
                                            :
                                            null
                                        }
                                        {false && this.state.data1 ?

                                            <tr>
                                                <td>Adresa primaoca:</td>
                                                <td>{this.state.data1.primaocAdresa}</td>
                                            </tr>
                                            :
                                            null
                                        }
                                        {this.state.data1 ?

                                            <tr>
                                                <td>Primalac plaća poštarinu:</td>
                                                <td>{this.state.data1.obveznikPlacanja == 0 ? 'NE' : 'DA'}</td>
                                            </tr>
                                            :
                                            null
                                        }
                                        {false && this.state.data1 ?

                                            <tr>
                                                <td>Iznos naplate pouzećem:</td>
                                                <td>{this.state.data1.iznosNaplatePouzecem}</td>
                                            </tr>
                                            :
                                            null
                                        }


                                        <tr>
                                            <td>Datum slanja pošiljke:</td>
                                            <td>{moment(this.state.data.datumSlanja).format('DD MMM YYYY, HH:mm')}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Vrsta pošiljke:</td>
                                            <td>{this.state.data.opisPosiljke}</td>
                                        </tr> */}
                                        <tr>
                                            <td>Masa (kg):</td>
                                            <td>{this.state.data.tezina}</td>
                                        </tr>
                                        <tr>
                                            <td>Broj paketa:</td>
                                            <td>{this.state.data.brojPaketa}</td>
                                        </tr>
                                        <tr>
                                            <td>Pošiljku preuzeo:</td>
                                            <td>{this.state.data.posiljkuPreuzeo ? this.state.data.posiljkuPreuzeo : '-'}</td>
                                        </tr> 
                                    </tbody>
                                </Table>

                            </Col>
                            :
                            null
                        }

                        <Col lg="12">
                            <p style={{ marginBottom: 20 }}>Ovdje možete prijaviti izmjenu za pošiljku ili dogovoriti dostavu za neki drugi dan bez pozivanja naše korisničke podrške.
                                Nakon prijave izmjene, javiće vam se ovisno o vrsti izmjene naš operater ili ćete dobiti obavještenje putem SMS/Viber poruke.</p>

                        </Col>


                    </Row>
                    {
                        this.state._error2 ?
                            <Row>
                                <Col lg="12">
                                    <p style={{ marginTop: '10px', color: '#DA212E' }}>{this.state._error2}</p>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col lg="6">
                                    <div className={this.state.deliveryNewDay ? 'tracking-option tracking-option-opened' : 'tracking-option'} onClick={() => {
                                        this.setState({
                                            deliveryNewDay: !this.state.deliveryNewDay,
                                            deliveryOffice: false,
                                            deliveryNewAddress: false,
                                            deliveryUnwanted: false,
                                            deliveryWrongPhone: false,
                                        })
                                    }}>
                                        <div className="tracking-option-container">
                                            <Isvg src={option1Icon} />
                                            <h6>Želim dostavu drugi dan</h6>
                                        </div>
                                        {
                                            this.state.deliveryNewDay ?

                                                <Isvg src={minusIcon} className="minus-icon" />
                                                :
                                                <Isvg src={plusIcon} className="plus-icon" />
                                        }
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className={this.state.deliveryOffice ? 'tracking-option tracking-option-opened' : 'tracking-option'} onClick={() => {
                                        this.setState({
                                            deliveryOffice: !this.state.deliveryOffice,
                                            deliveryNewDay: false,
                                            deliveryNewAddress: false,
                                            deliveryUnwanted: false,
                                            deliveryWrongPhone: false,
                                        })
                                    }}>
                                        <div className="tracking-option-container">
                                            <Isvg src={option2Icon} />
                                            <h6>Želim da dođem po pošiljku u poslovnicu</h6>
                                        </div>
                                        {
                                            this.state.deliveryOffice ?

                                                <Isvg src={minusIcon} className="minus-icon" />
                                                :
                                                <Isvg src={plusIcon} className="plus-icon" />
                                        }
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className={this.state.deliveryNewDay ? 'delivery-form opened' : 'delivery-form'}>
                                        <div className="form">
                                            <h6>Želim dostavu drugi dan</h6>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="new-day-date">Izaberite datum</Label>
                                                        <Date onChange={(date) => { this.setState({ date }) }} value={this.state.date}></Date>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label for="new-day-requirment">Obrazložite vaš zahtjev</Label>
                                                        <Input type="textarea" name="new-day-requirment" id="new-day-requirment" onChange={(e) => this.setState({ napomena: e.target.value })} value={this.state.napomena} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className={this.state.deliveryOffice ? 'delivery-form opened' : 'delivery-form'}>
                                        <div className="form">
                                            <h6>Želim da dođem po pošiljku u poslovnicu EuroExpress brze pošte</h6>
                                            <Row>
                                                {/* <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="office-date">Izaberite datum</Label>
                                                        <Date onChange={(date) => { this.setState({ date }) }} value={this.state.date}></Date>
                                                    </FormGroup>
                                                </Col> */}
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="office-location">Odaberite drugu lokaciju na kojoj želite da preuzmete vašu pošiljku</Label>
                                                        <Input type="select" name="office-location" id="office-location" placeholder="Izaberite lokaciju" onChange={(e) => this.setState({ addressId: e.target.value })} value={this.state.addressId}>
                                                            {
                                                                this.state.data1 ?
                                                                    [{ id: null, adresa: 'Izberite adresu', mestoOpis: '' }, ...this.state.data1.adrese].map((item, idx) => {
                                                                        return (
                                                                            <option value={item.id}>{item.adresa} {item.mestoOpis}</option>
                                                                        )
                                                                    })
                                                                    :
                                                                    null
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    {/* <FormGroup>
                                                        <Label for="office-date">Izaberite datum</Label>
                                                        <Date onChange={(date) => { this.setState({ date }) }} value={this.state.date}></Date>
                                                    </FormGroup> */}
                                                </Col>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label for="office-requirment">Dodatna napomena</Label>
                                                        <Input type="textarea" name="text" id="office-requirment" onChange={(e) => this.setState({ napomena: e.target.value })} value={this.state.napomena} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className={this.state.deliveryNewAddress ? 'tracking-option tracking-option-opened' : 'tracking-option'} onClick={() => {
                                        this.setState({
                                            deliveryNewAddress: !this.state.deliveryNewAddress,
                                            deliveryOffice: false,
                                            deliveryNewDay: false,
                                            deliveryWrongPhone: false,
                                            deliveryUnwanted: false
                                        })
                                    }}>
                                        <div className="tracking-option-container">
                                            <Isvg src={option3Icon} />
                                            <h6>Želim dostavu na drugu adresu</h6>
                                        </div>
                                        {
                                            this.state.deliveryNewAddress ?

                                                <Isvg src={minusIcon} className="minus-icon" />
                                                :
                                                <Isvg src={plusIcon} className="plus-icon" />
                                        }
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className={this.state.deliveryWrongPhone ? 'tracking-option tracking-option-opened' : 'tracking-option'} onClick={() => {
                                        this.setState({
                                            deliveryWrongPhone: !this.state.deliveryWrongPhone,
                                            deliveryOffice: false,
                                            deliveryNewDay: false,
                                            deliveryNewAddress: false,
                                            deliveryUnwanted: false
                                        })
                                    }}>
                                        <div className="tracking-option-container">
                                            <Isvg src={option4Icon} />
                                            <h6>Pošiljka nije za mene - pogrešan broj telefona</h6>
                                        </div>
                                        {
                                            this.state.deliveryWrongPhone ?

                                                <Isvg src={minusIcon} className="minus-icon" />
                                                :
                                                <Isvg src={plusIcon} className="plus-icon" />
                                        }
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className={this.state.deliveryNewAddress ? 'delivery-form opened' : 'delivery-form'}>
                                        <div className="form">
                                            <h6>Želim dostavu na drugu adresu</h6>
                                            <Row>
                                                {/* <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="new-address-date">Izaberite datum</Label>
                                                        <Date onChange={(date) => { this.setState({ date }) }} value={this.state.date}></Date>
                                                    </FormGroup>
                                                </Col> */}
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label for="new-address-place">Unesite adresu i mjesto</Label>
                                                        <Input type="text" name="new-address-place" id="new-address-place" onChange={(e) => this.setState({ napomena: e.target.value })} value={this.state.napomena} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className={this.state.deliveryWrongPhone ? 'delivery-form opened' : 'delivery-form'}>
                                        <div className="form">
                                            <h6>Pošiljka nije za mene - pogrešan broj telefona</h6>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label for="wrong-number-requirment">Obrazložite vaš zahtjev</Label>
                                                        <Input type="textarea" name="wrong-number-requirment" id="wrong-number-requirment" onChange={(e) => this.setState({ napomena: e.target.value })} value={this.state.napomena} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className={this.state.deliveryUnwanted ? 'tracking-option tracking-option-opened' : 'tracking-option'} onClick={() => {
                                        this.setState({
                                            deliveryUnwanted: !this.state.deliveryUnwanted,
                                            deliveryOffice: false,
                                            deliveryNewDay: false,
                                            deliveryNewAddress: false,
                                            deliveryWrongPhone: false
                                        })
                                    }}>
                                        <div className="tracking-option-container">
                                            <Isvg src={option5Icon} />
                                            <h6>Ne želim da preuzmem pošiljku</h6>
                                        </div>
                                        {
                                            this.state.deliveryUnwanted ?

                                                <Isvg src={minusIcon} className="minus-icon" />
                                                :
                                                <Isvg src={plusIcon} className="plus-icon" />
                                        }
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <Link to={`/pracenje-posiljke/${this.props[0].match.params.code}`}>
                                        <div className="tracking-option">
                                            <div className="tracking-option-container">
                                                <Isvg src={option6Icon} />
                                                <h6>Praćenje pošiljke</h6>
                                            </div>
                                            <Isvg src={plusIcon} className="plus-icon" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col lg="12">
                                    <div className={this.state.deliveryUnwanted ? 'delivery-form opened' : 'delivery-form'}>
                                        <div className="form">
                                            <h6>Ne želim da preuzmem pošiljku</h6>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label for="unwanted-requirment">Obrazložite zašto odbijate uručenje pošiljke</Label>
                                                        <Input type="textarea" name="unwanted-requirment" id="unwanted-requirment" onChange={(e) => this.setState({ napomena: e.target.value })} value={this.state.napomena} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                {this.state.deliveryOffice ||
                                    this.state.deliveryNewDay ||
                                    this.state.deliveryNewAddress ||
                                    this.state.deliveryUnwanted ||
                                    this.state.deliveryWrongPhone ?

                                    < Col lg="12" className="delivery-direction-button">
                                        {!this.state.done ?
                                            <Button type="submit" onClick={this.submitRequest}>POŠALJI ZAHTJEV</Button>
                                            :
                                            <p style={{ marginTop: 20, }}>Zahtjev uspješno poslat</p>
                                        }
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                    }



                </Container>
            </div >
        )
    }
}

export default PageWithLayout(ShipmentDirection)
