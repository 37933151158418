import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import Picture1 from '../../images/picture1.png';
import AnimtedButton from '../../components/animatedButton';
import GoogleMap from '../../components/map';
import PageWithLayout from '../../containers/page';
import MenuComponent from '../../components/menuComponent';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink, Input
} from 'reactstrap'

const links = [
    {
        _id: 1,
        link: '/kontakt/kontakt-formular',
        name: 'Kontak formular'
    },
    {
        _id: 2,
        link: '/kontakt/informacije-za-inostrane-pošiljke',
        name: 'Informacije za inostrane pošiljke'
    },
    {
        _id: 3,
        link: '/kontakt/mapa-distributivnih-centara',
        name: 'Mapa distributivnih centara'
    }
];


class branchNetwork extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Kontakt</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Kontakt</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                <MenuComponent links={links} />
                            </div>
                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <h6>Mreža naših poslovnica</h6>
                            <p>Detaljne podatke o nekom od naših centara možete dobiti klikom na <span>crveni marker</span> označen na mapi.</p>
                            <p>Dobićete tačnu lokaciju centra, adresu i radno vrijeme.</p>
                        </Col>
                    </Row>
                </Container>
                <div className="googleMapWrapper2">
                    <GoogleMap {...this.props}></GoogleMap>
                </div>
            </div>
        )
    }
}

export default PageWithLayout(branchNetwork)
