import React, { Component } from 'react'
import News from '../../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../../containers/page';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'
import { API_ENDPOINT } from '../../constants';


class about extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: []
        }
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/services', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                services: result
            })

        })


    }


    render() {
        let page = {
            sections: []
        };
        let parent = {
            sections: []
        };

        for (let i = 0; i < this.state.services.length; i++) {
            if (this.state.services[i].alias == this.props[0].match.params.alias) {
                page = this.state.services[i];
                break;
            }
        }

        for (let i = 0; i < this.state.services.length; i++) {
            if (this.state.services[i].alias == this.props[0].match.params.parent) {
                parent = this.state.services[i];
                break;
            }
        }


        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>{this.props[0].match.params.parent ? parent.name : 'Naše usluge'}</h6>
                            {this.props[0].match.params.parent ?
                                <div className="links">

                                    <NavItem>
                                        <Link to="/">Početna</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/">{parent.name}</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to={`/usluge/${parent.alias}/${page.alias}`}>{page.name}</Link>
                                    </NavItem>
                                </div>
                                :
                                <div className="links">

                                    <NavItem>
                                        <Link to="/">Početna</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to={`/usluge/${page.alias}`}>{page.name}</Link>
                                    </NavItem>
                                </div>
                            }
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        {this.props[0].match.params.parent ?
                            <Col xl="3" lg="3">
                                <div className="desktopSidemenu">
                                    <h6>Meni</h6>
                                    {
                                        this.state.services.map((item, idx) => {
                                            if (item.parent == parent._id)
                                                return (
                                                    <NavItem>
                                                        <NavLink to={`/usluge/${parent.alias}/${item.alias}`} exact tag={RRNavLink} activeClassName="active">
                                                            {item.name}
                                                        </NavLink>
                                                    </NavItem>

                                                )
                                        })
                                    }
                                </div>
                            </Col>
                            :
                            null

                        }

                        <Col xl={this.props[0].match.params.parent ? "9" : "12"} lg={this.props[0].match.params.parent ? "9" : "12"} md="12" xs="12">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h6>{page.name}</h6>
                                    </Col>
                                    {
                                        page.sections.map((item, idx) => {
                                            return (
                                                <Col lg={item.width.desktop} xs={item.width.mobile}>
                                                    {
                                                        item.value.type == 'image' ?
                                                            <img src={item.value.value} className="img-fluid" />

                                                            :
                                                            <div dangerouslySetInnerHTML={{ __html: item.value.value }}></div>
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <News />

            </div>
        )
    }
}

export default PageWithLayout(about)
