import React, { Component } from 'react';
import { Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import About from './views/aboutUs/aboutHistory';
import DomesticTransport from './views/basicServices/domesticTransport';
import NewsPage from './views/news/newsPage';
import NewsDetailPage from './views/news/newsDetail.js';
import ShipmentTracking from './views/shipmentTracking.js';
import ShipmentDirection from './views/shipmentDirection';
import ContactForm from './views/contact/contactForm';
import ContactBranchNetwork from './views/contact/branchNetwork';
import ShippingInfoForForeginContries from './views/contact/shippingInfoForForeginContries';
import Education from './views/hiring/education';
import OpenPositions from './views/hiring/openPositions';
import Map from './views/map';

import './App.css';
import './animate.css';
import GoogleMap from './googleMapScript';
import ScrollIntoView from './scrollIntoView';
import SendPackage from './views/sendPackage';
import NewsMediaPage from './views/news/newsMediaPage';
import Hiring from './views/hiring';
import Zones from './views/zones';
import Static from './views/static';
import 'whatwg-fetch';
import createHistory from 'history/createBrowserHistory'
import { API_ENDPOINT } from './constants.js';

const history = createHistory()

String.prototype.generateAlias = function () {
  let str = this;
  str = str.toLowerCase();
  str = str.replace(/\s\s+/g, ' ');
  str = str.replace(/ /g, '-');
  str = str.replace(/\./g, '-');
  str = str.replace(/\,/g, '-');
  str = str.replace(/š/g, 's');
  str = str.replace(/č/g, 'c');
  str = str.replace(/ć/g, 'c');
  str = str.replace(/đ/g, 'dj');
  str = str.replace(/ž/g, 'z');
  str = str.replace(/\//g, '-');
  str = str.replace(/\\/g, '-');
  return str;
}


class App extends React.Component {
  constructor(props) {
    super(props);

    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;

    this.state = {
      static: {

      }
    };

  }

  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount() {
    fetch(API_ENDPOINT + '/static', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => res.json()).then((pages) => {
      this.setState({
        static: pages
      })
    })
    history.listen((location, action) => {
      //console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
      //console.log(`The last navigation action was ${action}`)
      //console.log(location.search);
      console.log(location);
      if (typeof window.gtag === 'function') {

        /*window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview');*/

        window.gtag('config', 'UA-134694101-2', {
          'page_title': window.document.title,
          'page_location': window.location.href,
          'page_path': location.pathname
        });


      }

    })

  }


  render() {
    return (
      <div style={{ position: 'relative' }}>
        <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router history={history}>
          <ScrollIntoView>
            <Switch>
              <Route exact path="/slanje-posiljke" render={(...renderProps) => (<SendPackage {...renderProps} {...this.state}></SendPackage>)} />

              <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state}></Home>)} />
              <Route exact path="/o-nama/:alias" render={(...renderProps) => (<About {...renderProps} {...this.state}></About>)} />
              {/*              
              <Route exact path="/o-nama/misija-i-vizija" render={(...renderProps) => (<MissionAndVision {...renderProps} {...this.state}></MissionAndVision>)} />
              <Route exact path="/o-nama/uprava-kompanije" render={(...renderProps) => (<CompanyManagment {...renderProps} {...this.state}></CompanyManagment>)} />
              <Route exact path="/o-nama/mreža-naših-poslovnica" render={(...renderProps) => (<BranchNetwork {...renderProps} {...this.state}></BranchNetwork>)} />
*/}
              <Route exact path="/usluge/:parent/:alias" render={(...renderProps) => (<DomesticTransport {...renderProps} {...this.state}></DomesticTransport>)} />
              <Route exact path="/usluge/:alias" render={(...renderProps) => (<DomesticTransport {...renderProps} {...this.state}></DomesticTransport>)} />
              <Route exact path="/stranica/:alias" render={(...renderProps) => (<Static {...renderProps} {...this.state}></Static>)} />

              {/*<Route exact path="/dodatne-usluge/tenderska-dokumentacija" render={(...renderProps) => (<TenderDocumentation {...renderProps} {...this.state}></TenderDocumentation>)} />
              <Route exact path="/usluge-na-poseban-zahtjev/kurir-na-dan" render={(...renderProps) => (<SpecialRequestServices {...renderProps} {...this.state}></SpecialRequestServices>)} />
*/}

              <Route exact path="/novosti/aktuelna-dešavanja" render={(...renderProps) => (<NewsPage {...renderProps} {...this.state}></NewsPage>)} />
              <Route exact path="/novosti/kutak-za-medije" render={(...renderProps) => (<NewsMediaPage {...renderProps} {...this.state}></NewsMediaPage>)} />

              <Route exact path="/novosti/:alias/:id" render={(...renderProps) => (<NewsDetailPage {...renderProps} {...this.state}></NewsDetailPage>)} />
              <Route exact path="/praćenje-pošiljke" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />
              <Route exact path="/praćenje-pošiljke/:code" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />

              <Route exact path="/zaposlenje/stranica/:alias" render={(...renderProps) => (<Education {...renderProps} {...this.state}></Education>)} />
              <Route exact path="/kontakt" render={(...renderProps) => (<ContactForm {...renderProps} {...this.state}></ContactForm>)} />
              <Route exact path="/kontakt/mapa-distributivnih-centara" render={(...renderProps) => (<ContactBranchNetwork {...renderProps} {...this.state}></ContactBranchNetwork>)} />
              <Route exact path="/kontakt/informacije-za-inostrane-pošiljke" render={(...renderProps) => (<ShippingInfoForForeginContries {...renderProps} {...this.state}></ShippingInfoForForeginContries>)} />
              <Route exact path="/pracenje-posiljke" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />
              <Route exact path="/pracenje-posiljke/:code" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />
              <Route exact path="/upravljanje-dostavom/:code/:phone" render={(...renderProps) => (<ShipmentDirection {...renderProps} {...this.state}></ShipmentDirection>)} />

              <Route exact path="/zaposlenje/prijavi-se" render={(...renderProps) => (<Hiring {...renderProps} {...this.state}></Hiring>)} />
              <Route exact path="/zone-dostava" render={(...renderProps) => (<Zones {...renderProps} {...this.state}></Zones>)} />
              <Route exact path="/iframe-map" render={(...renderProps) => (<Map {...renderProps} {...this.state}></Map>)} />

            </Switch>
          </ScrollIntoView>
        </Router>

      </div>


    )
  }
}

export default App;
